import React from 'react';
import { imgBaseUrl } from '../../App';
import '../../css/homepage.css';
import { useLocalizedConfig } from '../Common/Config';

function AboutMe() {
    const config = useLocalizedConfig("aboutme.config.json");

    return (
        <section className="about-section spad" id="about" style={{ backgroundImage: "url(" + imgBaseUrl + (config ? config.overlay_image : "") + ")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-1 mb-lg-0">
                        <img src={config ? (imgBaseUrl + config.me_image) : undefined} alt="selfPortrait" />
                    </div>
                    <div className="col-lg-6 about-text">
                        <h1 className="hero-title">{config && config.aboutme_title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: config && config.content }}></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutMe;