import React from 'react';
import ScrollToTop from 'react-router-scroll-top';
import './css/homepage.css';
import Homepage from './components/Home/Homepage';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LanguageContextProvider } from './components/Common/LanguageProvider';

export const imgBaseUrl = "/images/";

axios.defaults.baseURL = "/";
library.add(far, fab, fas);

function MyHomepage() {
  return (
    <Router>
      <ScrollToTop>
        <LanguageContextProvider>
          <Switch>
            <Route path="/">
              <Homepage />
            </Route>
          </Switch>
        </LanguageContextProvider>
      </ScrollToTop>
    </Router>
  );
}

export default MyHomepage;