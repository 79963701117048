import React, { useEffect, useState, useContext } from 'react';
import { imgBaseUrl } from '../../App';
import { useLocalizedConfig } from '../Common/Config';
import ReadMoreText from '../Common/ReadMoreText';
import { LanguageContext } from '../Common/LanguageProvider';

function ActivityItem(props) {
  return <div className="resume-item wow fadeInUp" data-wow-delay="0.3s">
    <h2 style={{ color: "black" }}>{props.activityName}</h2>
    <h3>{props.activityTime}</h3>
    <p>
      <ReadMoreText text={props.activityDescription} />
      {" "}
      <div>
        {props.activityLink ? <a href={props.activityLink.link} style={{ fontWeight: "bold" }}>{props.activityLink.text}</a> : null}
      </div>
    </p>
    <br />
    {props.activityImage && <img loading="lazy" src={imgBaseUrl + props.activityImage} style={{ width: "100%", padding: `${props.imagePadding}px` }} alt="Something went wrong" />}
  </div>
}

function Activity() {
  const [activity, setActivity] = useState();
  const config = useLocalizedConfig("activity.config.json");
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    setActivity(config);
  }, [config]);

  return (
    <section className="resume-section spad" id="update">
      <div className="container">
        <div className="section-title">
          <h2>{activity && activity.title}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {activity && activity.items.slice(0, activity.display_number).map((item) =>
              <ActivityItem activityName={item.title}
                activityTime={item.time}
                activityDescription={item.description}
                activityImage={item.image}
                activityLink={item.link}
                imagePadding={item.padding}
              />
            )}
            {activity && activity.display_number >= activity.items.length ? null : <button className="site-btn" style={{ width: "100%" }}
              onClick={() => {
                setActivity({ ...activity, display_number: activity.display_number + 3 })
              }}>{{ en: "View Previous Activities & Updates", zh: "点击查看历史动态/项目" }[lang]}</button>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Activity;