import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocalizedConfig } from './Config';

function Footer() {
    const footer = useLocalizedConfig("footer.config.json");

    return (
        <footer className="footer-section spad">
            <div className="container">
                <div className="social">
                    {footer && footer.social_media.map(i => {
                        const [iconType, iconName] = i.icon.split(", ")
                        return <a href={i.link}><FontAwesomeIcon icon={[iconType, iconName]} /></a>
                    })}
                </div>
                <div className="copyright" dangerouslySetInnerHTML={{ __html: footer ? footer.text : "" }}></div>
            </div>
        </footer>
    );
}

export default Footer;