import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext } from 'react';
import '../../css/homepage.css';
import { LanguageContext } from './LanguageProvider';

function Navigation() {
    const { lang, setLang } = useContext(LanguageContext);

    const switchLanguage = useCallback(() => {
        console.log(lang);
        setLang(lang === "en" ? "zh" : "en");
    }, [lang, setLang]);

    function offsetTo(id) {
        let el = document.documentElement
        let st = document.getElementById(id)
        el.scrollTop = st.offsetTop
    }

    return (
        <div className={"nav-bar"}>
            <ul>
                <li><h6 onClick={() => {offsetTo("home")}}><FontAwesomeIcon icon="home" /></h6></li>
                <li><h6 onClick={() => {offsetTo("about")}}>{{ en: "About", zh: "关于" }[lang]}</h6></li>
                <li><h6 onClick={() => {offsetTo("update")}}>{{ en: "Update", zh: "动态" }[lang]}</h6></li>
                <li><h6 onClick={() => {offsetTo("gallery")}}>{{ en: "Gallery", zh: "照片库" }[lang]}</h6></li>
                <li><h6 onClick={() => {offsetTo("contact")}}>{{ en: "Contact", zh: "联系我" }[lang]}</h6></li>
                <li><h6 onClick={switchLanguage}>{{ en: "中", zh: "En" }[lang]}</h6></li>
                {/* <FontAwesomeIcon icon="globe" /> */}
            </ul>
        </div>
    );
}

export default Navigation;