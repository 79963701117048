import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as lodash from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from "tsparticles-slim";
import { imgBaseUrl } from '../../App';
import '../../css/homepage.css';
import { useLocalizedConfig } from '../Common/Config';
import particleConfig from './particle.config.json';

function SocialLinks() {
    const social = useLocalizedConfig("footer.config.json");

    return <div className="social-links">
        {social?.social_media?.map(i => {
            const [iconType, iconName] = i.icon.split(", ");
            return <a href={i.link} className="highlight"><FontAwesomeIcon icon={[iconType, iconName]} /></a>
        })}
    </div>;
}

function Intro() {
    const contents = useLocalizedConfig("intro.config.json");
    const [bg, setBg] = useState();

    useEffect(() => {
        if (contents?.intro_images) {
            const { path, color } = lodash.sample(contents.intro_images);
            setBg(path);
            // set primary color
            const stylesheet = document.styleSheets[0];
            stylesheet.insertRule(`.highlight { color: ${color}; }`);
        }
    }, [contents]);

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    
    return (
        <section className="intro-section" style={bg && { backgroundImage: "url(" + imgBaseUrl + bg + ")" }} id="home">
            <div style={{ position: "absolute" }}>
            <Particles
                id="tsparticles" init={particlesInit} loaded={particlesLoaded}
                options={particleConfig} />
            </div>
            
            <div className="intro-content">
                <div className="intro-inner">
                    <div className="container">
                        <h2 dangerouslySetInnerHTML={{ __html: contents?.title }}></h2>
                        <h3 style={{ whiteSpace: "pre-wrap" }}>{contents?.subtitle}</h3>
                        <div style={{ height: 50 }}></div>
                        {
                            contents?.links?.map(({icon, html}) => (
                                <h3 style={{ color: "white" }}><FontAwesomeIcon icon={icon} /> <span dangerouslySetInnerHTML={{__html: html}}></span></h3>
                            ))
                        }
                        <div style={{ height: 50 }}></div>
                        <SocialLinks />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
