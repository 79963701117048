import React, { useContext } from 'react';
import classnames from 'classnames';
import * as lodash from 'lodash';
import { LanguageContext } from './LanguageProvider';


const ReadMoreText = ({ text }) => {
  const [clamped, setClamped] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);
  const containerRef = React.useRef(null);
  const handleClick = () => setClamped(!clamped);

  const { lang } = useContext(LanguageContext);

  React.useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <>
      <div
        ref={containerRef}
        className={classnames("long-text", clamped && "clamp")}
      >
        {text}
      </div>
      {showButton && (
        <a onClick={handleClick} style={{ fontWeight: "bold" }} href="javascript:void(0)">
          {{ en: `Read ${clamped ? "more" : "less"}`, zh: `点此${clamped ? "展开" : "收起"}`}[lang]}
        </a>
      )}
    </>
  );
};

export default ReadMoreText;
