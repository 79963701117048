import React, { useContext, useState } from 'react';
import { LanguageContext } from '../Common/LanguageProvider';

function Contact() {
    const { lang } = useContext(LanguageContext);
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleMessageChange = (event) => {
        setMessage({ message: event.target.value.split(' ').join('%20') })
    }

    const handleNameChange = (event) => {
        setName({ name: event.target.value.split(' ').join('%20') })
    }

    const handleSubjectChange = (event) => {
        setSubject({ subject: event.target.value.split(' ').join('%20') })
    }

    return (
        <section className="contact-section spad" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="con-info wow fadeInUp" data-wow-delay="0.2s">
                            <h2 style={{ color: "black" }}>{{ en: "Contact Me", zh: "联系我" }[lang]}</h2>
                            <h2>{{ en: "If you need to contact me, please enter details below and hit Send Message", 
                                   zh: "如果你需要联系我，请在下方输入留言后点击发送信息" }[lang]}</h2>
                        </div>

                        <form className="contact-form" id="contact-form">
                            <div className="row">
                                <div className="col-sm-12">
                                    <input type="text" name="name" onChange={(e) => handleNameChange(e)} id="name" required placeholder={{ en: "Your Name", zh: "姓名" }[lang]} />
                                </div>
                                <div className="col-sm-12">
                                    <input type="text" name="subject" onChange={(e) => handleSubjectChange(e)} required placeholder={{ en: "Email Subject", zh: "邮件题目" }[lang]} />
                                </div>
                                <div className="col-sm-12">
                                    <textarea id="message" name="message" onChange={(e) => handleMessageChange(e)} required placeholder={{ en: "Your Message", zh: "信息内容" }[lang]}></textarea>
                                    <div className="text-center">
                                        <button type="button" className="site-btn" id="send-form">
                                            <a href={"mailto:1418756682@qq.com"
                                                + "?subject=From " + name + ": " + subject
                                                + "&body=" + message
                                            } style={{ color: "white" }}>{{ en: "Send Message", zh: "发送信息" }[lang]}</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;